<template>
  <respect />
  <project />
</template>

<script>
// @ is an alias to /src
import respect from "@/components/home/RespectApp.vue";
import project from "@/components/home/ProjectApp.vue";

export default {
  name: "HomeView",
  components: {
    respect,
    project,
  },
};
</script>
