<template>
  <swiper
    :slidesPerView="1"
    :parallax="true"
    :modules="modules"
    :autoplay="{ delay: 5000 }"
    :effect="'slide'"
    :speed="600"
    :loop="true"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    class="swiper-container"
  >
    <swiper-slide class="color-1">
      <div class="overlay">Domy modułowe w <br />przystępnej cenie</div>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, EffectCube } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

export default {
  name: "SliderApp",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      modules: [Autoplay, EffectCube],
      onSwiper,
      onSlideChange,
    };
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style>
/* Styl dla slidera */
.swiper-container {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  padding: 0 20px;
  text-shadow: #000 4px 3px 5px;
  line-height: 55px;
}
.color-1 {
  background: url("https://mis.info.pl/domy/wp-content/uploads/2024/01/cropped-4-RemoteMediaFile_6619344_0_2023_10_21_12_11_24.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  animation: scale 20s linear infinite;
}

@keyframes scale {
  50% {
    transform: scale(1.2);
  }
}
</style>
