<template>
  <section class="container">
    <div class="sectionHeader">
      <div class="sectionHeading">
        <h3>co nas wyróżnia ?</h3>
      </div>
    </div>
    <div class="respectWrapper">
      <div class="respectItem">
        <i class="ri-home-5-line"></i>
        <h5>Błyskawiczna realizacja</h5>
        <p>
          Kiedy dasz nam znać, że jesteś gotowy do rozpoczęcia budowy, stawiamy
          się na miejscu już w ciągu dwóch tygodni. Twoje własne cztery kąty
          postawimy w dwa miesiące!
        </p>
      </div>
      <div class="respectItem">
        <i class="ri-home-5-line"></i>
        <h5>
          Przystępne <br />
          ceny
        </h5>
        <p>
          Kiedy dasz nam znać, że jesteś gotowy do rozpoczęcia budowy, stawiamy
          się na miejscu już w ciągu dwóch tygodni. Twoje własne cztery kąty
          postawimy w dwa miesiące!
        </p>
      </div>
      <div class="respectItem">
        <i class="ri-home-5-line"></i>
        <h5>Gwarancja bezpieczeństwa</h5>
        <p>
          Kiedy dasz nam znać, że jesteś gotowy do rozpoczęcia budowy, stawiamy
          się na miejscu już w ciągu dwóch tygodni. Twoje własne cztery kąty
          postawimy w dwa miesiące!
        </p>
      </div>
      <div class="respectItem">
        <i class="ri-home-5-line"></i>
        <h5>
          Terminy <br />
          realizacji
        </h5>
        <p>
          Kiedy dasz nam znać, że jesteś gotowy do rozpoczęcia budowy, stawiamy
          się na miejscu już w ciągu dwóch tygodni. Twoje własne cztery kąty
          postawimy w dwa miesiące!
        </p>
      </div>
    </div>
  </section>
</template>
<style>
.respectWrapper {
  background-color: var(--section-color);
  display: flex;
  column-gap: 20px;

  .respectItem {
    flex: 1;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--white-color);
    text-align: center;

    i {
      font-size: 100px;
      color: #fd5d14;
    }

    h5 {
      font-size: 18px;
      text-transform: uppercase;
      padding-bottom: 15px;
      line-height: 24px;
    }

    p {
      font-size: 13px;
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 567px) {
  .respectWrapper {
    flex-direction: column;
    row-gap: 20px;
  }
}
</style>
