<template>
  <Header />
  <Slider />
  <router-view />
  <div style="height: 500px"></div>
  <Footer />
</template>
<script>
import Footer from "./components/FooterApp.vue";
import Header from "./components/HeaderApp.vue";
import Slider from "./components/SliderApp.vue";

export default {
  components: {
    Footer,
    Header,
    Slider,
  },
};
</script>
<style lang="scss">
/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --black-color: #18243c;
  --section-color: #efefef;
  --black-color-light: hsl(220, 24%, 15%);
  --black-color-lighten: hsl(220, 20%, 18%);
  --white-color: #fff;
  --body-color: hsl(220, 100%, 97%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --normal-font-size: 0.938rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  position: relative;
  height: 100%;
}
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  position: relative;
  height: 100%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--white-color);
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

.sectionHeader {
  max-width: 540px;
  padding-bottom: 30px;
  text-align: center;
  position: relative;
  z-index: 10;
  margin: 40px auto 25px;
}

.sectionHeader .sectionHeading h3 {
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600px;
}

.sectionHeader .sectionHeading:after {
  content: "";
  height: 5px;
  width: 80px;
  background-color: #fd5d14;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  margin: 0 auto;
}

/* For large devices */
@media screen and (min-width: 1200px) {
  .container {
    margin-inline: auto;
  }
}
</style>
