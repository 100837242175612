<template>
  <section class="container">
    <div class="sectionHeader">
      <div class="sectionHeading">
        <h3>Projekty MIŚ</h3>
      </div>
    </div>
    <div class="projectWrapper">
      <div class="projectItem">
        <div class="projectImg">
          <img
            src="https://www.hausformator.pl/assets/images/house/KS110/KS110_1s.jpg"
          />
        </div>
        <h5 class="projectTitle">MIŚ 72</h5>
        <p class="projectDesc">
          ok. 72 m²- powierzchnia uwzględnia zastosowanie ścian z prefabrykacji
        </p>
        <div class="projectPropertyBox">
          <div class="projectPropertyBoxT">
            <p>Pow. podłogi</p>
            <div class="projectPropertyBox2">
              <i class="ri-calculator-line"></i>
              <p>72 m²</p>
            </div>
          </div>
          <div>
            <p>Pow. zabudowy</p>
            <div class="projectPropertyBox2">
              <i class="ri-shape-2-line"></i>
              <p>69,2 m²</p>
            </div>
          </div>
          <div>
            <p>Liczba pokoi</p>
            <div class="projectPropertyBox2">
              <i class="ri-group-fill"></i>
              <p>4</p>
            </div>
          </div>
        </div>
        <div class="projectButton"><a href="#">Więcej</a></div>
      </div>
      <div class="projectItem">
        <div class="projectImg">
          <img
            src="https://www.hausformator.pl/assets/images/house/KS110/KS110_1s.jpg"
          />
        </div>
        <h5 class="projectTitle">MIŚ 100</h5>
        <p class="projectDesc">
          ok. 100 m²- powierzchnia uwzględnia zastosowanie ścian z prefabrykacji
        </p>
        <div class="projectPropertyBox">
          <div class="projectPropertyBoxT">
            <p>Pow. podłogi</p>
            <div class="projectPropertyBox2">
              <i class="ri-calculator-line"></i>
              <p>100 m²</p>
            </div>
          </div>
          <div>
            <p>Pow. zabudowy</p>
            <div class="projectPropertyBox2">
              <i class="ri-shape-2-line"></i>
              <p>94,3 m²</p>
            </div>
          </div>
          <div>
            <p>Liczba pokoi</p>
            <div class="projectPropertyBox2">
              <i class="ri-group-fill"></i>
              <p>6</p>
            </div>
          </div>
        </div>
        <div class="projectButton"><a href="#">Więcej</a></div>
      </div>
      <div class="projectItem">
        <div class="projectImg">
          <img
            src="https://www.hausformator.pl/assets/images/house/KS110/KS110_1s.jpg"
          />
        </div>
        <h5 class="projectTitle">MIŚ 120</h5>
        <p class="projectDesc">
          ok. 120 m²- powierzchnia uwzględnia zastosowanie ścian z prefabrykacji
        </p>
        <div class="projectPropertyBox">
          <div class="projectPropertyBoxT">
            <p>Pow. podłogi</p>
            <div class="projectPropertyBox2">
              <i class="ri-calculator-line"></i>
              <p>120 m²</p>
            </div>
          </div>
          <div>
            <p>Pow. zabudowy</p>
            <div class="projectPropertyBox2">
              <i class="ri-shape-2-line"></i>
              <p>115,2 m²</p>
            </div>
          </div>
          <div>
            <p>Liczba pokoi</p>
            <div class="projectPropertyBox2">
              <i class="ri-group-fill"></i>
              <p>8</p>
            </div>
          </div>
        </div>
        <div class="projectButton"><a href="#">Więcej</a></div>
      </div>
    </div>
  </section>
</template>
<style>
.projectWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  margin-bottom: 40px;

  .projectItem {
    flex: 2; /* Równomierne rozłożenie kolumn */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    min-width: 0;

    .projectImg {
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        transition: 0.3s ease-out;
      }

      img:hover {
        transform: scale(1.1); /* Powiększenie obrazu po najechaniu */
      }
    }

    .projectTitle {
      font-size: 24px;
      margin-top: 10px;
    }

    .projectDesc {
      padding-bottom: 20px;
    }

    .projectButton {
      background: #fd5d14;
      padding: 20px;
      border-radius: 20px;
      width: 175px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      margin: 20px auto;
      transition: background-color 0.5s ease;
    }

    .projectButton:hover {
      background-color: var(--black-color);
    }
  }
}

.projectPropertyBox {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  font-size: 14px;
  position: relative;
}

.projectPropertyBoxT::before {
  content: "";
  height: 3px;
  width: 200px;
  background-color: #fd5d14;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  bottom: 0px;
  text-align: center;
  margin: 0 auto;
}

.projectPropertyBox2 {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 567px) {
}

@media screen and (max-width: 1199px) {
  .projectWrapper {
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: 20px;
  }
}
</style>
