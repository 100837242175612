<template>
  <footer>
    <div class="footer container">
      <div class="footerLogo">Logo</div>
      <div class="footerContact">
        <h5>Kontakt</h5>
        <div>
          <p>
            e-mail:
            <a href="mailto:biuro@mis-bud.pl">biuro@mis-bud.pl</a><br />
            tel. +48 511 599 344 <br />
            <a href="https://mis.info.pl/">www.mis.info.pl</a>
          </p>
        </div>
      </div>
      <div class="footerInvestor">
        <h5>Inwestor</h5>
        <div>
          <p>
            <strong>Domy MIŚ</strong> <br />
            ul. Rybnicka 61,<br />
            44-238 Czerwionka-Leszczyny
          </p>
        </div>
      </div>
    </div>
    <div class="copyright">Wszystkie prawa zastrzeżone © 2024 Domy MIŚ</div>
  </footer>
</template>
<style>
/*=============== FOOTER ===============*/
footer {
  background-color: var(--black-color);
  color: var(--white-color);
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 68px 0px;

    .footerContact {
      & p {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 1px;
        margin: 0 0 25px;
        line-height: 30px;
      }
    }

    .footerInvestor {
      & p {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 1px;
        margin: 0 0 25px;
        line-height: 30px;
      }
    }
  }
  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0px;
    font-size: 12px;
  }
}

.footerContact h5,
.footerInvestor h5 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 16px;
  letter-spacing: 4px;
  font-weight: 500;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 567px) {
  footer {
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0px;
      flex-direction: column;
      text-align: center;
    }
  }
}
</style>
